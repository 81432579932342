import { Injectable } from '@angular/core';
import { WalletStore, WalletState } from './wallet.store.js';
import { Query } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class WalletQuery extends Query<WalletState> {
  constructor(protected override store: WalletStore) {
    super(store);
  }
}
