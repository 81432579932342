import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DepositsStore, DepositsState } from './deposits.store.js';
// import { DepositsStore, DepositsState, DepositsUIState } from './deposits.store';

@Injectable({ providedIn: 'root' })
export class DepositsQuery extends QueryEntity<DepositsState> {
  // ui!: EntityUIQuery<DepositsUIState>;
  constructor(protected override store: DepositsStore) {
    super(store);
    // this.createUIQuery();
  }
}
