import { WalletQuery } from '../states/wallet/wallet.query.js';
import { DepositsQuery } from './../states/deposits/deposits.query.js';
import { DepositsGear } from './../gears/deposits.gear.js';
import { Injectable, inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DepositsFacade {
  walletQuery = inject(WalletQuery);
  depositsQuery = inject(DepositsQuery);
  deposits$ = this.depositsQuery.selectAll()
  wallet$ = this.walletQuery.select();

  constructor(private depositsGear: DepositsGear) { }

  getAllDeposits() {
    return this.depositsGear.getAllDeposits();
  }

  getAllDepositsByUserId() {
    return this.depositsGear.getAllDepositsByUserId();
  }

  getDepositPendingByUserId() {
    return this.depositsGear.getDepositPendingByUserId();
  }

  createDeposit(depositNid: any, value: any, payment: any, userId: any) {
    return this.depositsGear.createDeposit(depositNid, value, payment, userId);
  }

  updateDeposit(depositId: any, status: any) {
    return this.depositsGear.updateDeposit(depositId, status);
  }

  releaseCoin(depositId: any, status: any) {
    return this.depositsGear.releaseCoin(depositId, status);
  }

  getUserWallet() {
    return this.depositsGear.getUserWallet();
  }

  createLimit(limit: any) {
    return this.depositsGear.createLimit(limit);
  }

  updateLimit(limit: any) {
    return this.depositsGear.updateLimit(limit);
  }

  updateAkitaWallet(rewardAmount: string) {
    return this.depositsGear.updateAkitaWallet(rewardAmount);
  }

  sendNotiDepositMail() {
    return this.depositsGear.sendNotiDepositMail();
  }
}
