import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Wallet } from '../../models/index.js';

export interface WalletState extends Wallet { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'wallets', resettable: true })
export class WalletStore extends Store<Wallet> {
  constructor() {
    super({});
  }
}
