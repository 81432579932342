import { WalletStore } from '../states/wallet/wallet.store.js';
import { DepositsStore } from './../states/deposits/deposits.store.js';
import { DepositsApi } from '../apis/deposits.api.js';
import { Injectable, inject } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { WalletQuery } from '../states/wallet/index.js';
import { CurrentUserFacade } from '@awread/core/users';

@Injectable({ providedIn: 'root' })
export class DepositsGear {
  currentUserFacade = inject(CurrentUserFacade);

  depositsApi = inject(DepositsApi);
  depositsStore = inject(DepositsStore);
  walletStore = inject(WalletStore);
  walletQuery = inject(WalletQuery);

  getAllDeposits() {
    return this.depositsApi.getAllDeposits().pipe(tap((res: any) => this.depositsStore.set(res)));
  }

  getAllDepositsByUserId() {
    return this.depositsApi.getAllDepositsByUserId().pipe(tap((res: any) => this.depositsStore.set(res)));
  }

  getDepositPendingByUserId() {
    return this.depositsApi.getDepositPendingByUserId();
  }

  createDeposit(depositNid: any, value: any, payment: any, userId: any) {
    return this.depositsApi.createDeposit(depositNid, value, payment, userId).pipe(tap((res: any) => this.depositsStore.add(res)));
  }

  updateDeposit(depositId: any, status: any) {
    return this.depositsApi.updateDeposit(depositId, status).pipe(tap((res: any) => this.depositsStore.update(res)));
  }

  releaseCoin(depositId: any, status: any) {
    return this.depositsApi.releaseCoin(depositId, status).pipe(tap((res: any) => this.depositsStore.update(res)));
  }

  getUserWallet(userId = this.currentUserFacade.getUserId()) {
    return this.depositsApi.getUserWallet(userId).pipe(tap((res: any) => this.walletStore.update(res)));
  }

  createLimit(limit: any) {
    return this.depositsApi.createLimit(limit).pipe(switchMap(res => this.getUserWallet()));
  }

  updateLimit(limit: any) {
    return this.depositsApi.updateLimit(limit).pipe(switchMap(res => this.getUserWallet()));
  }

  updateAkitaWallet(rewardAmount: string) {
    const currentWallet = this.walletQuery.getValue();
    return this.walletStore.update({
      ...currentWallet,
      balance: +currentWallet.balance + +rewardAmount,
    });
  }

  sendNotiDepositMail() {
    return this.depositsApi.sendNotiDepositMail();
  }
}
